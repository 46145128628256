// export const LOCALES ={
//     ENGLISH:'en-us',
//     GERMAN:'de-de',
//     FRENCH:'fr-ca',
//     SPANISH:'es-ES',
// };
export const LOCALES ={
    ENGLISH:'ENGLISH',
    GERMAN:'GERMAN',
    FRENCH:'FRENCH',
    SPANISH:'SPANISH',
    ARABIC:'ARABIC',
};