import React, { useState, useEffect } from "react";
import traslate from "../i18n/translate";
import RingIcon from "../assets/images/icons/ring__icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleActiveTab,
  handleSwapTabs
} from "../actions/header/headerActions";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import {
  handleDiamondDetails,
  handleSettingDetails
} from "../actions/chooseYourSetting/chooseYourSettingActions";
import {
  handleComaparePageIds,
} from "../actions/selectYourDiamond/selectYourDiamond";
import { useLocation, useNavigate } from "react-router-dom";
import SettingIcon from "../assets/images/icons/setting__icon.svg";
import DiamondIcon from "../assets/images/icons/diamond__icon.svg";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const currentTab = useSelector((state) => state.headerReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramDiamindId = new URLSearchParams(search).get("diamondId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  const showHeader = new URLSearchParams(search).get("showHeader");
  const isChange = new URLSearchParams(search).get("isChange");
  const [tabDetails, setTabDetails] = useState([]);
  const [tabSetting, setTabSetting] = useState(false);
  const [tabdiamond, setTabDiamond] = useState(false);
  const diamondDetails = useSelector(
    (state) => state.chooseYourSettingReducer.diamondDetails
  );
  const settingDetails = useSelector(
    (state) => state.chooseYourSettingReducer.settingDetails
  );

  useEffect(() => {
    const tabDetails = [
      {
        tabInitial: "Choose Your",
        tabName: "Settings",
        tabValue: "chooseYourSetting",
        imgSrc: SettingIcon
      },
      {
        tabInitial: "Select Your",
        tabName: "Diamond",
        tabValue: "selectYourDiamond",
        imgSrc: DiamondIcon
      }
    ];
    if (isChange == "True") {
      dispatch(handleSwapTabs(tabDetails));
    }
  }, []);

  const handleTabClick = (tab, index) => {
    dispatch(handleComaparePageIds(""));

    let tabDetailsArray = tabDetails;

    if (tab == "completeYourRing") {
      if (currentTab) {
        if (currentTab.isSettingViewEdit && currentTab.isDiamondViewEdit) {
          dispatch(handleActiveTab(tab));
        }
      }
    } else if (index == tabDetailsArray.length - 1) {
      let temp = tabDetailsArray[0];
      tabDetailsArray[0] = tabDetailsArray[1];
      tabDetailsArray[1] = temp;
      dispatch(handleSwapTabs(tabDetailsArray));
      dispatch(handleActiveTab(tab));
    } else {
      dispatch(handleActiveTab(tab));
    }
    if (tab == "chooseYourSetting") {
      navigate(
        "/" + `?DealerLink=${paramdealerId}&headerTab=chooseYourSetting`
      );
    } else if (tab == "selectYourDiamond") {
      navigate(
        "/SelectYourDiamond" + `?DealerLink=${paramdealerId}&isChange=True`
      );
    }
  };

  useEffect(() => {
    if (currentTab) {
      if (currentTab.tabDetails) {
        setTabDetails(currentTab.tabDetails);
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (paramSettingId == "null" || paramSettingId == null) {
      setTabSetting(false);
    } else {
      setTabSetting(true);
    }

    if (paramDiamindId == "null" || paramDiamindId == null) {
      setTabDiamond(false);
    } else {
      setTabDiamond(true);
    }
  }, [window.location.pathname]);

  let DiamondData = diamondDetails;
  let SettingData = settingDetails;

  const handleViewSetting = () => {
    ///Need to set page name
    if (paramDiamindId == "null" || paramDiamindId == null) {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${SettingData.settingID}&settingNumber=${SettingData.settingNumber}&headerTab=chooseYourSetting&childType="RingChildId"`
      );
      dispatch(handleDiamondDetails({}));
    } else {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${SettingData.settingID}&settingNumber=${SettingData.settingNumber}&childType="RingChildId"&diamondId=${paramDiamindId}&pageName=${DiamondData.pageName}&headerTab=chooseYourSetting`
      );
    }
  };

  const handleEditSetting = () => {
    localStorage.removeItem("selectedRingSize")
    localStorage.removeItem("selectedSettingData")
    if (paramDiamindId == "null" || paramDiamindId == null) {
      navigate("/" + `?DealerLink=${paramdealerId}&headerTab=chooseYourSetting`);
      dispatch(handleDiamondDetails({}));
    } else {
      navigate(
        "/" +
          `?DealerLink=${paramdealerId}&settingShape=${DiamondData.txtShape}&diamondId=${DiamondData.intDiamondId}&pageName=${DiamondData.pageName}&headerTab=chooseYourSetting`
      );
    }
  };

  const handleViewDiamond = () => {
    if (paramSettingId == "null" || paramSettingId == null) {
      navigate(
        "/viewDiamondDetails" +
          `?isSocialPage=True&DealerLink=${loginDetails.dealerId}&diamondId=${DiamondData.intDiamondId}&pageNo=1&isAllowed=True&pageName=${DiamondData.pageName}&shapeFilters=${DiamondData.shapeFilters}&txtCutlet=${JSON.parse(localStorage.getItem("selectedDiamondData"))?.txtCutGrade}&txtClarity=${DiamondData.txtClarity}&intColorPriority=${DiamondData.intColorPriority}`
      );
      dispatch(handleSettingDetails({}));
    } else {
      navigate(
        "/viewDiamondDetails" +
          `?isSocialPage=True&DealerLink=${loginDetails.dealerId}&diamondId=${DiamondData.intDiamondId}&pageNo=1&isAllowed=True&pageName=${DiamondData.pageName}&shapeFilters=${DiamondData.shapeFilters}&txtCutlet=${JSON.parse(localStorage.getItem("selectedDiamondData"))?.txtCutGrade}&txtClarity=${DiamondData.txtClarity}&intColorPriority=${DiamondData.intColorPriority}&settingId=${SettingData.settingID}&settingNumber=${paramSettingNumber}&headerTab=selectYourDiamond`
      );
    }
  };

  const handleEditDiamond = () => {
    let selectedSetting = JSON.parse(localStorage.getItem("selectedSettingData"))

    if (paramSettingId == "null" || paramSettingId == null) {
      navigate(
        "/SelectYourDiamond" + `?DealerLink=${paramdealerId}&isChange=True`
      );
      dispatch(handleSettingDetails({}));
    } else {
      navigate(
        "/SelectYourDiamond" +
          `?DealerLink=${paramdealerId}&setParamValues=True&settingShape=${selectedSetting?.centerStonesThatFit}&centerStoneMinimumCarat=${SettingData.centerStoneMinimumCarat}&centerStoneMaximumCarat=${SettingData.centerStoneMaximumCarat}&settingId=${SettingData.settingID}&isChange=True&settingNumber=${SettingData.settingNumber}&headerTab=selectYourDiamond`
      );
    }
  };
  const headerTab = new URLSearchParams(search).get("headerTab");
  return (
    <>
      <div className="bcontainer" id="topHeader">
        <div className="breadcrumb">
          {tabDetails &&
            tabDetails.map((tab, index) => {
              return (
                <li
                  key={index}
                  className={headerTab == tab.tabValue && "active"}
                  onClick={() => handleTabClick(tab.tabValue, index)}
                >
               <div className="left__block">
   <span className="circle"> {index + 1}</span>
  <div className="breadcrumb__content">
    <p className="mb-0">{traslate(tab.tabInitial)}</p>
    <p className="subheading m-0">{traslate(tab.tabName)}</p>
  </div>
</div>


                  <div className="right__block">
                    {tab.tabValue == "chooseYourSetting" &&
                    tabSetting == true ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {" "}
                        <div className="mr-1">
                          <span
                            onClick={() => handleViewSetting()}
                            className="linkText"
                          >
                            {traslate("View")}
                          </span>
                          <span className="mx-3">|</span>
                          <span
                            onClick={() => handleEditSetting()}
                            className="linkText"
                          >
                            {traslate("Edit")}
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {tab.tabValue == "selectYourDiamond" &&
                    tabdiamond == true ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {" "}
                        <div className="mr-1">
                          <span
                            onClick={handleViewDiamond}
                            className="linkText"
                          >
                              {traslate("View")}
                          </span>
                          <span className="mx-3">|</span>
                          <span
                            onClick={handleEditDiamond}
                            className="linkText"
                          >
                            {traslate("Edit")}
                          </span>
                        </div>
                      </div>
                    ) : null}

                    <img src={tab.imgSrc} />
                  </div>
                </li>
              );
            })}

          <li
            className={headerTab == "completeYourRing" && "active"}
            onClick={() => handleTabClick("completeYourRing")}
          >
            <div className="left__block">
              <span className="circle">3</span>
              <div className="breadcrumb__content">
                <p className="mb-0">{traslate("Complete Your")}</p>
                <p className="subheading m-0">  {traslate("Ring")}</p>
              </div>
            </div>

            <div className="right__block">
              <img src={RingIcon} />
            </div>
          </li>
        </div>
      </div>
      {showHeader == "True" && <SelectYourDiamond />}
    </>
  );
};

export default Header;
