import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./component/Layout";
import { I18Provider, LOCALES } from "./i18n";
import { useCookies } from "react-cookie";

const ChooseYourSetting = lazy(() =>
  import("./component/ApplicationPreview/ChooseYourSetting")
);
const SelectYourDiamond = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond")
);
const CompleteYourRing = lazy(() =>
  import("./component/ApplicationPreview/CompleteYourRing")
);
const ChooseSettingItemDetails = lazy(() =>
  import("./component/ApplicationPreview/ChooseSettingItemDetails")
);
const MinedDiamond = lazy(() =>
  import("./component/common/SelectYourDiamond/MinedDiamond")
);
const LabGrown = lazy(() =>
  import("./component/common/SelectYourDiamond/LabGrown")
);
const Compare = lazy(() =>
  import("./component/common/SelectYourDiamond/Compare")
);
const Request = lazy(() =>
  import("./component/common/SelectYourDiamond/Request")
);
const ViewDiamondDetails = lazy(() =>
  import("./component/common/SelectYourDiamond/ViewDiamondDetails")
);

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;
  const currentLocale = Object.keys(LOCALES).filter((id) => {
    if (LOCALES[id] === (language ? language.toUpperCase() : LOCALES.ENGLISH))
      return LOCALES[id];
  });

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <Suspense fallback={""}>
      <I18Provider locale={currentLocale}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                path="/SelectYourDiamond"
                element={<SelectYourDiamond />}
              />
              <Route path="/" element={<ChooseYourSetting />} />
              <Route
                path="/ChooseSettingItemDetails"
                element={<ChooseSettingItemDetails />}
              />
              <Route
                path="/viewDiamondDetails"
                element={<ViewDiamondDetails />}
              />

              <Route path="/CompleteYourRing" element={<CompleteYourRing />} />

              <Route path="/Mined" element={<MinedDiamond />} />
              <Route path="/Lab" element={<LabGrown />} />
              <Route path="/Request" element={<Request />} />
              <Route path="/Compare" element={<Compare />} />
            </Route>
          </Routes>
        </BrowserRouter>
        </I18Provider>
      </Suspense>
      
    </div>
  );
};

export default App;
